import React, { Component } from "react";
import PropTypes from "prop-types";
import DropdownItem from "./DropdownItem";

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownItems: this.props.items
    };
  }

  getRenderedContent() {
    return (
      <div style={{ color: this.props.themeColor }} className="dropdown">
        <div className="dropdown-content">
          <div className="dropdown-top"></div>
          <div className="dropdown-bottom">
            <ul className="dropdown-items">
              {this.state.dropdownItems.map((item, index) => (
                <DropdownItem
                  themeColor={this.props.themeColor}
                  text={item.text}
                  icon={item.icon}
                  action={item.action}
                  key={index + 1}
                  recipeId={this.props.recipeId}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

Dropdown.propTypes = {
  items: PropTypes.array.isRequired
};

export default Dropdown;
