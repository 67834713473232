import React, { Component } from "react";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import SVG from "react-inlinesvg";
import moon from "../images/icons/moon.svg";
import sun from "../images/icons/sun.svg";

class ThemeToggle extends Component {
  handleClick = () => {
    this.props.toggleTheme();
  };

  getRenderedContent() {
    let tooltipStr = !this.props.darkMode ? "Dark mode" : "Light mode";

    return (
      <Tippy
        animation="shift-away"
        arrow={false}
        boundary="viewport"
        className="label-tooltip"
        content={tooltipStr}
        delay={[400, 0]}
        duration={200}
      >
        <button className="icon-toggle" onClick={this.handleClick}>
          <div className="icon">
            <SVG src={moon} className="moon-icon" />
          </div>

          <div className="icon">
            <SVG src={sun} className="sun-icon" />
          </div>
        </button>
      </Tippy>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

export default ThemeToggle;
