import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "./Button";

class DropdownItem extends Component {
  getRenderedContent() {
    const { action, icon, text, themeColor } = this.props;

    return (
      <li className="dropdown-item">
        <Button
          classList={"button--translucent button--themed button--expand-bg"}
          themeColor={themeColor}
          action={action}
          icon={icon}
          text={text}
          recipeId={this.props.recipeId}
        />
      </li>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

DropdownItem.propTypes = {
  action: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired
};

export default DropdownItem;
