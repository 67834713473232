import React, { Component } from "react";
import PropTypes from "prop-types";
import FavoriteButton from "./FavoriteButton";
import DropdownMenu from "./DropdownMenu";
import RecipeHeader from "./RecipeHeader";
import Checklist from "./Checklist";
import SVG from "react-inlinesvg";
import clock from "../images/icons/clock.svg";
import person from "../images/icons/person.svg";

class Recipe extends Component {
  constructor(props) {
    super(props);
    this.menu = React.createRef();
    this.state = {
      name: this.props.recipe.name,
      id: this.props.recipe.id,
      prepTime: this.props.recipe.prepTime,
      servings: this.props.recipe.servings,
      photo: this.props.recipe.photo,
      ingredients: this.props.recipe.ingredients,
      directions: this.props.recipe.directions,
      themeColor: this.props.recipe.themeColor,
      favorite: this.props.recipe.favorite
    };
  }

  // Lifecycle methods ======================================

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.recipe.id !== this.props.recipe.id) {
      this.props.resetScroll();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      name: nextProps.recipe.name,
      id: nextProps.recipe.id,
      prepTime: nextProps.recipe.prepTime,
      servings: nextProps.recipe.servings,
      photo: nextProps.recipe.photo,
      ingredients: nextProps.recipe.ingredients,
      directions: nextProps.recipe.directions,
      themeColor: nextProps.recipe.themeColor,
      favorite: nextProps.recipe.favorite
    };
  }

  // Custom methods =========================================

  updateLocalStorage = () => {
    let oldRecipes = JSON.parse(localStorage.getItem("recipes"));
    const currentRecipe = { ...this.state };

    for (var i in oldRecipes) {
      if (oldRecipes[i].id === currentRecipe.id) {
        oldRecipes[i] = currentRecipe;
      }
    }

    let updatedRecipes = [...oldRecipes];
    localStorage.setItem("recipes", JSON.stringify(updatedRecipes));
  };

  getRenderedContent() {
    const {
      name,
      id,
      prepTime,
      servings,
      photo,
      ingredients,
      directions,
      themeColor,
      favorite
    } = this.state;

    const dropdownItems = [
      {
        action: this.props.downloadRecipe,
        icon: "download",
        text: "Download"
      },
      {
        action: this.props.editRecipe,
        icon: "pencil",
        text: "Edit"
      },
      {
        action: this.props.requestDelete,
        icon: "trash-can",
        text: "Delete"
      }
    ];

    return (
      <div className="recipe" data-id={id}>
        <RecipeHeader themeColor={themeColor} photo={photo} />
        <section className="recipe-content">
          <FavoriteButton
            favorite={favorite}
            updateLocal={this.props.updateLocal}
            updateFavorite={this.props.updateFavorite}
            id={id}
          />
          <section className="recipe-top">
            <div className="recipe-name-menu-ctr">
              <h2 className="recipe-name">{name}</h2>
              <DropdownMenu
                items={dropdownItems}
                domRef={this.menu}
                themeColor={themeColor}
                recipeId={this.props.recipe.id}
              />
            </div>
            <div className="recipe-info">
              <ul
                className="recipe-preptime-servings icon-text-list inline"
                style={{ color: themeColor }}
              >
                <li className="recipe-preptime">
                  <SVG src={clock} />
                  <span>{prepTime}</span>
                </li>
                <li className="recipe-servings">
                  <SVG src={person} />
                  <span>{`${servings} servings`}</span>
                </li>
              </ul>
            </div>
          </section>
          <section className="ingredients-directions">
            <div className="ingredients">
              <h3>Ingredients</h3>
              <Checklist
                themeColor={themeColor}
                listData={ingredients}
                listType={"table"}
                id="ingredients"
                updateLocal={this.updateLocalStorage}
                updateRecipe={this.props.updateRecipe}
              />
            </div>

            <div className="directions">
              <h3>Directions</h3>
              <Checklist
                themeColor={themeColor}
                listData={directions}
                listType={"list"}
                id="directions"
                updateLocal={this.updateLocalStorage}
                updateRecipe={this.props.updateRecipe}
              />
            </div>
          </section>
        </section>
      </div>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

Recipe.propTypes = {
  recipe: PropTypes.object.isRequired
};

export default Recipe;
