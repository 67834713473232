import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Vibrant from "node-vibrant";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import SVG from "react-inlinesvg";
import Button from "./Button";
import UCWidgetWrapper from "./UCWidgetWrapper";
import rgbToHex from "../utils/rgbToHex";
import isValidHex from "../utils/isValidHex";
import questionMark from "../images/icons/question-mark.svg";
import pointer from "../images/icons/pointer.svg";
import hamburger from "../images/food/hamburger-bg.svg";
import avocados from "../images/food/avocados-bg.svg";
import grapes from "../images/food/grapes-bg.svg";
import broccoli from "../images/food/broccoli-bg.svg";
import watermelon from "../images/food/watermelon-bg.svg";
import carrots from "../images/food/carrots-bg.svg";
import bananas from "../images/food/bananas-bg.svg";
import sandwich from "../images/food/sandwich-bg.svg";

class RecipeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      submitAttempts: 0,
      hasChanges: this.props.hasChanges,
      fields: {
        recipeName: {
          errorText: "Cannot be blank.",
          required: true,
          showError: true,
          valid: false,
          value: ""
        },
        prepTime: {
          errorText: "Cannot be blank.",
          required: true,
          showError: true,
          valid: false,
          value: ""
        },
        servings: {
          errorText: "Cannot be blank.",
          required: true,
          showError: true,
          valid: false,
          value: ""
        },
        themeColor: {
          required: false,
          valid: true,
          value: ""
        },
        photo: {
          filename: "",
          required: false,
          value: "",
          valid: true
        },
        ingredients: {
          errorText: "Both the Name and Amount fields must be filled out.",
          list: [
            {
              id: 1,
              ingredientName: {
                error: false,
                valid: false,
                value: ""
              },
              ingredientAmount: {
                error: false,
                valid: false,
                value: ""
              },
              valid: false
            }
          ],
          required: true,
          showError: true,
          valid: false
        },
        directions: {
          errorText: "You must enter at least 1 step.",
          list: [
            {
              id: 1,
              error: false,
              value: "",
              valid: false
            }
          ],
          required: true,
          showError: true,
          valid: false
        }
      }
    };
  }

  // Custom methods =========================================

  addRow = (listName, data) => {
    let newState = { ...this.state };
    let newItem = {
      ...{ id: newState.fields[listName].list.length + 1 },
      ...data
    };
    newState.fields[listName].list.push(newItem);
    this.setState(newState, () => {
      this.validateField(listName);
      this.props.handleFormChange();
      this.focusNewInput(listName);
    });
  };

  deleteRow = (listName, id) => {
    let newState = { ...this.state };
    if (newState.fields[listName].list.length > 1) {
      newState.fields[listName].list = newState.fields[listName].list.filter(
        item => {
          return item.id !== id;
        }
      );

      newState.fields[listName].list.forEach((item, index) => {
        item.id = index + 1;
      });

      this.setState(newState, () => {
        this.validateField(listName);
        this.props.handleFormChange();
      });
    }
  };

  focusNewInput = listName => {
    const selector = `[data-form-item=${listName}] .input-row:last-child .input-ctr:first-child input`;
    document.querySelector(selector).focus();
  };

  getDeleteBtnStyle = (list, id) => {
    if (this.state.fields[list].list.length < 2 && id < 2) {
      return { opacity: 0, pointerEvents: "none" };
    }
    return {};
  };

  getRenderedContent() {
    let filenameStyle =
      this.state.fields.photo.filename === ""
        ? { display: "none" }
        : { display: "inline" };
    let rainbowCtrAttr = isValidHex(this.state.fields.themeColor.value);

    return (
      <div className="recipe-form-ctr">
        <h2>{this.props.title}</h2>
        <form
          id="new-recipe-form"
          name="new-recipe-form"
          onSubmit={this.handleSubmit}
          className="large"
        >
          <section className="form-items">
            <div className="form-item" data-form-item="name">
              <div className="label-row">
                <label htmlFor="name">Name</label>
              </div>
              <div className="input-row">
                <div className="input-ctr">
                  <input
                    name="recipeName"
                    type="text"
                    onChange={this.handleChange.bind(this, {})}
                    value={this.state.fields.recipeName.value}
                    data-form-item="recipeName"
                    data-has-error={
                      this.state.submitAttempts > 0 &&
                      !this.state.fields.recipeName.valid &&
                      this.state.fields.recipeName.showError
                    }
                  />
                </div>
              </div>
              <div className="error-row">
                <span
                  className="error"
                  data-visible={
                    this.state.submitAttempts > 0 &&
                    !this.state.fields.recipeName.valid &&
                    this.state.fields.recipeName.showError
                  }
                >
                  {this.state.fields.recipeName.errorText}
                </span>
              </div>
            </div>

            <div className="form-item" data-form-item="prep-time">
              <div className="label-row">
                <label htmlFor="prep-time">Prep Time</label>
              </div>
              <div className="input-row">
                <div className="input-ctr">
                  <input
                    name="prepTime"
                    type="text"
                    onChange={this.handleChange.bind(this, {})}
                    value={this.state.fields.prepTime.value}
                    data-form-item="prepTime"
                    data-has-error={
                      this.state.submitAttempts > 0 &&
                      !this.state.fields.prepTime.valid &&
                      this.state.fields.prepTime.showError
                    }
                  />
                </div>
              </div>
              <div className="error-row">
                <span
                  className="error"
                  data-visible={
                    this.state.submitAttempts > 0 &&
                    !this.state.fields.prepTime.valid &&
                    this.state.fields.prepTime.showError
                  }
                >
                  {this.state.fields.prepTime.errorText}
                </span>
              </div>
            </div>

            <div className="form-item" data-form-item="servings">
              <div className="label-row">
                <label htmlFor="servings">Servings</label>
              </div>
              <div className="input-row">
                <div className="input-ctr">
                  <input
                    name="servings"
                    type="text"
                    onChange={this.handleChange.bind(this, {})}
                    value={this.state.fields.servings.value}
                    data-form-item="servings"
                    data-has-error={
                      this.state.submitAttempts > 0 &&
                      !this.state.fields.servings.valid &&
                      this.state.fields.servings.showError
                    }
                  />
                </div>
              </div>
              <div className="error-row">
                <span
                  className="error"
                  data-visible={
                    this.state.submitAttempts > 0 &&
                    !this.state.fields.servings.valid &&
                    this.state.fields.servings.showError
                  }
                >
                  {this.state.fields.servings.errorText}
                </span>
              </div>
            </div>

            <div className="form-item" data-form-item="theme-color">
              <div className="label-row">
                <label htmlFor="theme-color">
                  Theme Color
                  <div className="label-tooltip-root">
                    <Tippy
                      animation="shift-away"
                      boundary="viewport"
                      className="label-tooltip"
                      content="Optional: Theme color will be applied to UI components (buttons, checklist, etc.) for this recipe knocked back to about 20% opacity. Be sure to choose a color that will show up on both light and dark backgrounds. If left unspecified, the theme color will be set to the most prominent color in the photo used for this recipe."
                      delay={[400, 0]}
                      duration={200}
                    >
                      <div className="label-tooltip-icon">
                        <SVG src={questionMark} />
                      </div>
                    </Tippy>
                  </div>
                </label>
              </div>
              <div className="input-row">
                <div className="input-ctr">
                  <input
                    name="color-input"
                    type="text"
                    onChange={this.handleChange.bind(this, {})}
                    value={this.state.fields.themeColor.value}
                    data-form-item="themeColor"
                    placeholder="Format: #000000"
                  />
                </div>
                <div className="input-ctr color-picker-ctr">
                  <input
                    name="color-picker"
                    type="color"
                    onChange={this.handleChange.bind(this, {})}
                    value={this.state.fields.themeColor.value}
                    data-form-item="themeColor"
                  />
                  <div className="rainbow-ctr" data-hidden={rainbowCtrAttr}>
                    <SVG src={pointer} />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-item" data-form-item="photo">
              <div className="label-row">
                <label htmlFor="photo">
                  Photo
                  <div className="label-tooltip-root">
                    <Tippy
                      animation="shift-away"
                      boundary="viewport"
                      className="label-tooltip"
                      content="Optional: If no photo is chosen, a placeholder photo will be used instead."
                      delay={[400, 0]}
                      duration={200}
                    >
                      <div className="label-tooltip-icon">
                        <SVG src={questionMark} />
                      </div>
                    </Tippy>
                  </div>
                </label>
              </div>
              <div className="input-row">
                <UCWidgetWrapper setPhoto={this.setPhoto} />
                <span className="photo-filename" style={filenameStyle}>
                  {this.state.fields.photo.filename}
                </span>
              </div>
            </div>

            <div className="form-item" data-form-item="ingredients">
              <fieldset name="ingredients">
                <div className="label-row">
                  <legend>Ingredients</legend>
                </div>
                <ul className="input-rows">
                  {this.state.fields.ingredients.list.map(
                    (ingredient, index) => (
                      <li className="input-row" key={index + 1}>
                        <div className="input-ctr-group">
                          <div className="input-ctr">
                            <input
                              name="ingredientAmount"
                              type="text"
                              onChange={this.handleChange.bind(this, index + 1)}
                              placeholder="Amount"
                              value={ingredient.ingredientAmount.value}
                              data-form-item="ingredients"
                              data-has-error={
                                this.state.submitAttempts > 0 &&
                                !ingredient.ingredientAmount.valid &&
                                this.state.fields.ingredients.showError
                              }
                            />
                          </div>
                          <div className="input-ctr">
                            <input
                              name="ingredientName"
                              type="text"
                              onChange={this.handleChange.bind(this, index + 1)}
                              placeholder="Name"
                              value={ingredient.ingredientName.value}
                              data-form-item="ingredients"
                              data-has-error={
                                this.state.submitAttempts > 0 &&
                                !ingredient.ingredientName.valid &&
                                this.state.fields.ingredients.showError
                              }
                            />
                          </div>
                        </div>
                        <Button
                          classList="button button--translucent button--expand-bg button--themed"
                          themeColor="red"
                          action={() =>
                            this.deleteRow("ingredients", index + 1)
                          }
                          icon="trash-can"
                          style={this.getDeleteBtnStyle(
                            "ingredients",
                            index + 1
                          )}
                        />
                      </li>
                    )
                  )}
                </ul>
                <div className="error-row">
                  <span
                    className="error"
                    data-visible={
                      this.state.submitAttempts > 0 &&
                      !this.state.fields.ingredients.valid &&
                      this.state.fields.ingredients.showError
                    }
                  >
                    {this.state.fields.ingredients.errorText}
                  </span>
                </div>
                <div className="input-subtext-row">
                  <span className="input-subtext">Amount</span>
                  <span className="input-subtext">Name</span>
                </div>
                <div className="input-action-row">
                  <Button
                    classList="button button--translucent"
                    action={() =>
                      this.addRow("ingredients", {
                        ingredientName: {
                          valid: false,
                          value: ""
                        },
                        ingredientAmount: {
                          valid: false,
                          value: ""
                        }
                      })
                    }
                    icon="plus"
                    text="Add ingredient"
                  />
                </div>
              </fieldset>
            </div>

            <div className="form-item" data-form-item="directions">
              <fieldset name="directions">
                <div className="label-row">
                  <legend>Directions</legend>
                </div>
                <ul className="input-rows">
                  {this.state.fields.directions.list.map((direction, index) => (
                    <li className="input-row" key={index + 1}>
                      <div className="input-ctr">
                        <input
                          name="step"
                          type="text"
                          onChange={this.handleChange.bind(this, index + 1)}
                          value={direction.value}
                          data-form-item="directions"
                          data-has-error={
                            this.state.submitAttempts > 0 &&
                            !direction.valid &&
                            this.state.fields.directions.showError
                          }
                        />
                      </div>
                      <Button
                        classList="button button--translucent button--expand-bg button--themed"
                        themeColor="red"
                        action={() => this.deleteRow("directions", index + 1)}
                        icon="trash-can"
                        style={this.getDeleteBtnStyle("directions", index + 1)}
                      />
                    </li>
                  ))}
                </ul>
                <div className="error-row">
                  <span
                    className="error"
                    data-visible={
                      this.state.submitAttempts > 0 &&
                      !this.state.fields.directions.valid &&
                      this.state.fields.directions.showError
                    }
                  >
                    {this.state.fields.directions.errorText}
                  </span>
                </div>
                <div className="input-action-row">
                  <Button
                    classList="button button--translucent"
                    action={() =>
                      this.addRow("directions", { value: "", valid: false })
                    }
                    icon="plus"
                    text="Add step"
                  />
                </div>
              </fieldset>
            </div>
          </section>
          <section className="form-footer">
            <button className="button" type="submit">
              Submit
            </button>
          </section>
        </form>
      </div>
    );
  }

  getPlaceholder = () => {
    const bgList = [
      hamburger,
      avocados,
      grapes,
      broccoli,
      watermelon,
      carrots,
      bananas,
      sandwich
    ];
    let randIndex = Math.floor(Math.random() * bgList.length);
    return bgList[randIndex];
  };

  async getProminentColor(image) {
    let themeColor = await new Vibrant(image);
    return await themeColor.getPalette().then(palette => {
      let { rgb } = palette.Vibrant;
      let rgbStr = "rgb(";
      rgb.forEach((value, index) => {
        rgbStr += index < 2 ? `${value},` : `${value})`;
      });
      return rgbStr;
    });
  }

  handleChange = (id, e) => {
    let newState = { ...this.state };
    const target = e.target;
    const formItem = e.target.getAttribute("data-form-item");

    newState.fields[formItem].showError = false;

    if (formItem === "ingredients") {
      const inputName = e.target.name;
      newState.fields.ingredients.list.forEach(ingredient => {
        if (ingredient.id === id) {
          ingredient[inputName].value = target.value;
        }
      });
    } else if (formItem === "directions") {
      newState.fields.directions.list.forEach(step => {
        if (step.id === id) {
          step.value = target.value;
        }
      });
    } else {
      let value = target.value;
      if (formItem === "themeColor") {
        value = value.toUpperCase();
      }
      newState.fields[formItem].value = value;
    }

    this.setState(newState, () => {
      this.validateField(formItem);
      this.props.handleFormChange();
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    let prevState = { ...this.state };
    this.setState({ submitAttempts: prevState.submitAttempts + 1 }, () => {
      if (this.state.valid) {
        const { photo, prepTime, recipeName, servings, themeColor } = {
          ...this.state.fields
        };
        let { directions, ingredients } = { ...this.state.fields };

        directions = directions.list
          .map((direction, index) => {
            if (direction.value !== "") {
              return {
                checked: false,
                text: direction.value
              };
            } else {
              return undefined;
            }
          })
          .filter(direction => {
            return direction !== undefined;
          });

        directions.map((direction, index) => {
          return (direction.id = index + 1);
        });

        ingredients = ingredients.list
          .map((ingredient, index) => {
            if (ingredient.ingredientName.value !== "") {
              return {
                amount: ingredient.ingredientAmount.value,
                checked: false,
                name: ingredient.ingredientName.value
              };
            } else {
              return undefined;
            }
          })
          .filter(ingredient => {
            return ingredient !== undefined;
          });

        ingredients.map((ingredient, index) => {
          return (ingredient.id = index + 1);
        });

        let recipe = {
          name: recipeName.value,
          prepTime: prepTime.value,
          servings: servings.value,
          photo: photo.value !== "" ? photo.value : this.getPlaceholder(),
          directions: directions,
          favorite: false,
          ingredients: ingredients,
          themeColor: isValidHex(themeColor.value)
            ? themeColor.value
            : undefined
        };

        if (this.props.mode === "edit") {
          recipe.id = this.props.recipeData.id;
          recipe.favorite = this.props.recipeData.favorite;
        }

        if (recipe.themeColor === undefined) {
          this.getProminentColor(recipe.photo).then(rgbStr => {
            recipe.themeColor = rgbStr;
            this.props.action(recipe);
          });
        } else {
          this.props.action(recipe);
        }
      }
    });
  };

  validateField = formItem => {
    let newState = { ...this.state };

    if (formItem === "ingredients") {
      let completeRows = 0;
      let partialRows = 0;

      // Validate individual input
      newState.fields.ingredients.list.forEach(ingredient => {
        ingredient.ingredientName.valid =
          ingredient.ingredientName.value !== "";

        ingredient.ingredientAmount.valid =
          ingredient.ingredientAmount.value !== "";
      });

      // Validate row
      newState.fields.ingredients.list.forEach(ingredient => {
        const { ingredientName, ingredientAmount } = ingredient;
        if (ingredientName.valid && ingredientAmount.valid) {
          ingredient.valid = true;
          completeRows++;
        } else if (
          (ingredientName.valid && !ingredientAmount.valid) ||
          (!ingredientName.valid && ingredientAmount.valid)
        ) {
          ingredient.valid = false;
          partialRows++;
        }
      });

      // Validate fieldset
      newState.fields.ingredients.valid = completeRows > 0 && partialRows < 1;
    } else if (formItem === "directions") {
      let directionsValid = false;

      newState.fields.directions.list.forEach(step => {
        if (step.value !== "") {
          step.valid = true;
          directionsValid = true;
        } else {
          step.valid = false;
        }
      });

      newState.fields.directions.valid = directionsValid;
    } else {
      if (newState.fields[formItem].required) {
        newState.fields[formItem].valid =
          newState.fields[formItem].value !== "";
      }
    }

    this.setState(newState, () => {
      this.validateForm();
    });
  };

  validateForm = () => {
    let newState = { ...this.state };
    newState.valid = true;
    for (let key in newState.fields) {
      if (!newState.fields[key].valid) {
        newState.valid = false;
        newState.fields[key].showError = true;
      }
    }
    this.setState(newState);
  };

  setColorValue = color => {
    let newState = { ...this.state };
    newState.fields.themeColor.value = color;
    newState.fields.themeColor.valid = isValidHex(color);
    this.setState(newState);
  };

  setFieldValues = () => {
    let newFields = { ...this.state.fields };

    if (this.props.mode === "new") {
      newFields = {
        recipeName: {
          errorText: "Cannot be blank.",
          required: true,
          showError: true,
          valid: false,
          value: ""
        },
        prepTime: {
          errorText: "Cannot be blank.",
          required: true,
          showError: true,
          valid: false,
          value: ""
        },
        servings: {
          errorText: "Cannot be blank.",
          required: true,
          showError: true,
          valid: false,
          value: ""
        },
        themeColor: {
          required: false,
          valid: true,
          value: ""
        },
        photo: {
          filename: "",
          required: false,
          value: "",
          valid: true
        },
        ingredients: {
          errorText: "Both the Name and Amount fields must be filled out.",
          list: [
            {
              id: 1,
              ingredientName: {
                error: false,
                valid: false,
                value: ""
              },
              ingredientAmount: {
                error: false,
                valid: false,
                value: ""
              },
              valid: false
            }
          ],
          required: true,
          showError: true,
          valid: false
        },
        directions: {
          errorText: "You must enter at least 1 step.",
          list: [
            {
              id: 1,
              error: false,
              value: "",
              valid: false
            }
          ],
          required: true,
          showError: true,
          valid: false
        }
      };
    } else if (this.props.mode === "edit") {
      if (this.props.recipeData !== undefined) {
        let {
          directions,
          ingredients,
          name,
          photo,
          prepTime,
          servings,
          themeColor
        } = this.props.recipeData;
        let newState = JSON.parse(JSON.stringify(this.state));

        for (let field in newFields) {
          newFields[field].valid = true;
        }

        newFields.recipeName.value = name;
        newFields.prepTime.value = prepTime;
        newFields.servings.value = servings;
        newFields.themeColor.value = isValidHex(themeColor)
          ? themeColor.toUpperCase()
          : rgbToHex(themeColor).toUpperCase();
        newFields.photo.value = photo;
        newFields.photo.filename = photo;
        newFields.ingredients.list = [];
        newFields.directions.list = [];
        newState.valid = true;

        ingredients.forEach((ingredient, index) => {
          const { name, amount, id } = ingredient;
          newFields.ingredients.list.push({
            id: id,
            ingredientAmount: {
              error: false,
              valid: true,
              value: amount
            },
            ingredientName: {
              error: false,
              valid: true,
              value: name
            },
            valid: true
          });
        });

        directions.forEach((direction, index) => {
          const { text, id } = direction;
          newFields.directions.list.push({
            error: false,
            id: id,
            valid: true,
            value: text
          });
        });
      }
    }

    this.setState({ fields: newFields });
  };

  setPhoto = info => {
    let newState = { ...this.state };
    newState.fields.photo.filename = info.name;
    newState.fields.photo.value = info.cdnUrl;
    this.setState(newState);
  };

  throwError = data => {
    console.log(data.message);
  };

  // Lifecycle methods ======================================

  componentDidMount() {
    this.setFieldValues();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.mode !== this.props.mode) {
      this.setFieldValues();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hasChanges !== prevState.hasChanges) {
      return { hasChanges: nextProps.hasChanges };
    }
    return null;
  }

  render() {
    return this.getRenderedContent();
  }
}

RecipeForm.propTypes = {
  action: PropTypes.func.isRequired
};

export default RecipeForm;
