import React, { Component } from "react";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";
import download from "../images/icons/download.svg";
import pencil from "../images/icons/pencil.svg";
import trashCan from "../images/icons/trash-can.svg";
import reset from "../images/icons/reset.svg";
import plus from "../images/icons/plus-sign.svg";
import image from "../images/icons/image.svg";

class Button extends Component {
  handleClick = () => {
    if (this.props.recipeId) {
      this.props.action(this.props.recipeId);
    } else {
      this.props.action();
    }
  };

  getRenderedContent() {
    let iconID = this.props.icon;
    let icon;
    switch (iconID) {
      case "download":
        icon = download;
        break;
      case "pencil":
        icon = pencil;
        break;
      case "reset":
        icon = reset;
        break;
      case "trash-can":
        icon = trashCan;
        break;
      case "plus":
        icon = plus;
        break;
      case "image":
        icon = image;
        break;
      default:
        icon = undefined;
        break;
    }

    const { text, htmlType, style } = this.props;
    let { themeColor } = this.props;
    if (themeColor === "red" || themeColor === "danger") {
      themeColor = "rgb(255,63,99)";
    } else if (themeColor === "gray" || themeColor === "neutral") {
      themeColor = "rgb(140,148,198)";
    } else if (themeColor === "default" || themeColor === "periwinkle") {
      themeColor = "rgb(109, 104, 237)";
    }
    let buttonStyle = { ...style };
    let { classList } = this.props;
    let buttonContents;

    buttonStyle.color = themeColor;

    if (icon !== undefined) {
      if (text) {
        classList += " button--icon-text";
        buttonContents = (
          <React.Fragment>
            <SVG src={icon} />
            <span>{text}</span>
          </React.Fragment>
        );
      } else {
        buttonContents = <SVG src={icon} />;
      }
    } else {
      buttonContents = text;
    }

    return (
      <button
        className={`button ${classList}`}
        onClick={this.handleClick}
        style={buttonStyle || null}
        type={htmlType || "button"}
      >
        {buttonContents}
      </button>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

Button.propTypes = {
  action: PropTypes.func.isRequired
};

export default Button;
