import React, { Component } from "react";
// import PropTypes from "prop-types";
import SVG from "react-inlinesvg";
import search from "../images/icons/search.svg";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  handleChange = () => {
    let val = this.input.current.value;
    this.props.filterRecipes(val);
  };

  getRenderedContent() {
    return (
      <div id="recipe-search" className="input-ctr input-ctr--icon large">
        <SVG src={search} />
        <input
          type="search"
          placeholder="Search recipes"
          onChange={this.handleChange}
          ref={this.input}
        />
      </div>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

export default SearchBar;
