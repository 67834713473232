import React, { Component } from "react";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import "tippy.js/animations/shift-toward.css";
import SVG from "react-inlinesvg";
import plus from "../images/icons/plus-sign--stroke.svg";

class NewRecipeButton extends Component {
  state = {};

  handleClick = () => {
    this.props.onclick();
  };

  getRenderedContent() {
    return (
      <div className="actions">
        <Tippy
          animation="shift-toward"
          arrow={false}
          boundary="viewport"
          className="label-tooltip"
          content="New recipe"
          delay={[400, 0]}
          distance={20}
          duration={200}
          placement="left"
        >
          <button
            id="new-recipe-btn"
            className="shadow shadow--dark shadow--medium"
            onClick={this.handleClick}
          >
            <SVG src={plus} />
          </button>
        </Tippy>
      </div>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

export default NewRecipeButton;
