import React, { Component } from "react";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import SVG from "react-inlinesvg";
import boxGrid from "../images/icons/box-grid.svg";
import stackedBoxes from "../images/icons/stacked-boxes.svg";

class DisplayModeToggle extends Component {
  state = {
    displayMode: this.props.displayMode
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return { displayMode: nextProps.displayMode };
  }

  handleClick = () => {
    this.props.toggleDisplayMode();
  };

  getRenderedContent() {
    let tooltipStr =
      this.state.displayMode === "card" ? "Compact view" : "Card view";

    return (
      <Tippy
        animation="shift-away"
        arrow={false}
        boundary="viewport"
        className="label-tooltip"
        content={tooltipStr}
        delay={[400, 0]}
        duration={200}
      >
        <button
          className="icon-toggle"
          onClick={this.handleClick}
          data-display-mode={this.state.displayMode}
        >
          <div className="icon">
            <SVG src={stackedBoxes} className="compact-icon" />
          </div>

          <div className="icon">
            <SVG src={boxGrid} className="card-icon" />
          </div>
        </button>
      </Tippy>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

export default DisplayModeToggle;
