export default function checkForTouch() {
  window.addEventListener(
    "touchstart",
    function onFirstTouch() {
      // we could use a class
      document.body.setAttribute("data-touch", true);

      // we only need to know once that a human touched the screen, so we can stop listening now
      window.removeEventListener("touchstart", onFirstTouch, false);
    },
    false
  );
}
