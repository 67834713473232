import React, { Component } from "react";
import PropTypes from "prop-types";

class RecipeHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerStyle: {},
      headerImgStyle: {},
      headerOverlayStyle: {
        backgroundColor: this.props.themeColor,
        opacity: 0
      },
      themeColor: this.props.themeColor
    };
  }

  // Lifecycle methods ======================================

  componentDidMount() {
    this.getInitialScale();
    document
      .querySelector(".RecipeModal-content")
      .addEventListener("scroll", this.handleScroll);
  }

  // Custom methods =========================================

  getInitialScale() {
    let initialHeaderImgScale =
      parseFloat(
        window
          .getComputedStyle(this.headerImg)
          .getPropertyValue("transform")
          .split(",")[0]
          .replace("matrix(", "")
      ) * 100;
    this.setState({ initialHeaderImgScale });
  }

  handleScroll = e => {
    if (this.header !== null) {
      let scrollPos = e.target.scrollTop;
      let headerHeight = this.header.clientHeight;
      let { initialHeaderImgScale } = this.state;
      let scrollPct = scrollPos / headerHeight;
      let newY = scrollPct * -0.25;
      let newImgScale =
        (initialHeaderImgScale - scrollPct * (initialHeaderImgScale - 100)) /
        100;
      let headerStyle = {
        transform: `translateY(${newY}px)`
      };
      let headerImgStyle = {
        transform: `translate(-50%,-50%) scale(${newImgScale}`
      };
      let headerOverlayStyle = {
        backgroundColor: this.props.themeColor,
        opacity: scrollPct
      };
      this.setState({ headerStyle });
      this.setState({ headerImgStyle });
      this.setState({ headerOverlayStyle });
    }
  };

  getRenderedContent() {
    const { headerImgStyle, headerOverlayStyle, headerStyle } = this.state;

    return (
      <header
        className="recipe-header"
        ref={header => (this.header = header)}
        style={headerStyle}
      >
        <img
          src={this.props.photo}
          alt=""
          ref={headerImg => (this.headerImg = headerImg)}
          style={headerImgStyle}
        />
        <div
          className="header-overlay"
          ref={headerOverlay => (this.headerOverlay = headerOverlay)}
          style={headerOverlayStyle}
        ></div>
      </header>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

RecipeHeader.propTypes = {
  photo: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired
};

export default RecipeHeader;
