import React, { Component } from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";

class ChecklistItem extends Component {
  checkItem = () => {
    this.props.updateList(this.props.id);
  };

  getRenderedContent() {
    const data = this.props.itemData;
    const { listType, themeColor } = this.props;
    const colorStyle = { color: themeColor };

    if (listType === "table") {
      return (
        <tr
          style={colorStyle}
          data-checked={data.checked}
          className="ingredient-row"
        >
          <td>
            <Checkbox
              themeColor={colorStyle}
              checked={data.checked}
              checkItem={this.checkItem}
            />
          </td>
          <td className="ingredient-amount">{data.amount}</td>
          <td className="ingredient-name">{data.name}</td>
        </tr>
      );
    } else if (listType === "list") {
      return (
        <li
          style={colorStyle}
          className="direction"
          data-checked={data.checked}
          checked={data.checked}
        >
          <Checkbox
            data-checked={data.checked}
            checkItem={this.checkItem}
            checked={data.checked}
          />
          <span className="direction-name">{data.text}</span>
        </li>
      );
    }
  }

  render() {
    return this.getRenderedContent();
  }
}

ChecklistItem.propTypes = {
  itemData: PropTypes.object.isRequired,
  listType: PropTypes.string.isRequired,
  updateList: PropTypes.func.isRequired
};

export default ChecklistItem;
