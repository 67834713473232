export default function rgbToHex(str) {
  function valueToHex(v) {
    let hex = parseInt(v).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  var match = str.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

  let rgb = match
    ? {
        red: match[1],
        green: match[2],
        blue: match[3]
      }
    : {};

  return (
    "#" + valueToHex(rgb.red) + valueToHex(rgb.green) + valueToHex(rgb.blue)
  );
}
