import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown";
import equal from "fast-deep-equal";

class DropdownMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
    this.menu = React.createRef();
    this.menuButton = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClick);
  }

  handleClick = e => {
    if (this.menu.current !== null) {
      if (this.menu.current.contains(e.target)) {
        if (this.menuButton.current.contains(e.target)) {
          this.setState({ isOpen: !this.state.isOpen });
        }
      } else {
        this.setState({ isOpen: false });
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!equal(this.props.menuIsOpen, prevProps.menuIsOpen)) {
      this.toggleOpen();
    }
  };

  getRenderedContent() {
    return (
      <div
        className="recipe-menu"
        data-open={this.state.isOpen}
        ref={this.menu}
      >
        <button
          className="recipe-menu-btn"
          ref={this.menuButton}
          onClick={() => this.handleClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Dropdown
          items={this.props.items}
          recipeId={this.props.recipeId}
          themeColor={this.props.themeColor}
        />
      </div>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

DropdownMenu.propTypes = {
  items: PropTypes.array.isRequired
};

export default DropdownMenu;
