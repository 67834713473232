import React, { Component } from "react";
import PropTypes from "prop-types";
import Recipe from "./Recipe";
import RecipeForm from "./RecipeForm";

class RecipeModal extends Component {
  constructor(props) {
    super(props);
    this.recipeModalContentRef = React.createRef();
    this.state = {
      content: this.props.data.content,
      recipeData: this.props.data.recipeData,
      open: this.props.data.open,
      hasChanges: this.props.data.hasChanges
    };
  }

  // Custom methods =========================================

  getRenderedContent() {
    const { content, recipeData } = this.state;
    let recipeModalHtml;

    if (content === "show") {
      recipeModalHtml = (
        <Recipe
          recipe={recipeData}
          editRecipe={this.props.editRecipe}
          requestDelete={this.props.requestDelete}
          downloadRecipe={this.props.downloadRecipe}
          resetScroll={this.resetScroll}
          updateFavorite={this.props.updateFavorite}
          updateLocal={this.props.updateLocal}
        />
      );
    } else if (content === "new") {
      recipeModalHtml = (
        <RecipeForm
          action={this.props.addRecipe}
          mode="new"
          title="New Recipe"
          handleFormChange={this.props.handleFormChange}
          hasChanges={this.props.data.hasChanges}
        />
      );
    } else if (content === "edit") {
      recipeModalHtml = (
        <RecipeForm
          action={this.props.updateRecipe}
          recipeData={this.props.data.recipeData}
          mode="edit"
          title="Edit Recipe"
          handleFormChange={this.props.handleFormChange}
          hasChanges={this.props.data.hasChanges}
        />
      );
    } else {
      recipeModalHtml = null;
    }

    return (
      <div className="RecipeModal-root" data-open={this.state.open}>
        <div className="RecipeModal-overlay"></div>
        <div className="content">
          <div
            className="RecipeModal"
            ref={this.props.recipeModalRef}
            data-content={this.state.content}
          >
            <div className="RecipeModal-close-btn-ctr">
              <button
                className="RecipeModal-close-btn"
                onClick={this.props.requestRecipeModalClose}
              >
                <span></span>
                <span></span>
              </button>
            </div>
            <div
              className="RecipeModal-content"
              ref={this.recipeModalContentRef}
              data-content={this.state.content}
            >
              {recipeModalHtml}
            </div>
          </div>
        </div>
      </div>
    );
  }

  resetScroll = () => {
    this.recipeModalContentRef.current.scrollTop = 0;
  };

  // Lifecycle methods ======================================

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.content !== this.props.data.content) {
      this.resetScroll();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState) {
      return {
        content: nextProps.data.content,
        recipeData: nextProps.data.recipeData,
        open: nextProps.data.open,
        hasChanges: nextProps.data.hasChanges
      };
    }
    return null;
  }

  render() {
    return this.getRenderedContent();
  }
}

RecipeModal.propTypes = {
  data: PropTypes.object.isRequired
};

export default RecipeModal;
