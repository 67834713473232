import React, { Component } from "react";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";
import checkmark from "../images/icons/checkmark--stroke.svg";

class Checkbox extends Component {
  componentDidMount() {}

  getRenderedContent() {
    return (
      <button
        onClick={this.props.checkItem}
        className="checkbox"
        data-checked={this.props.checked}
      >
        <SVG src={checkmark} className="checkmark" />
      </button>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  checkItem: PropTypes.func.isRequired
};

export default Checkbox;
