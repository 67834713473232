import React, { Component } from "react";
import { Widget } from "@uploadcare/react-widget";
import Button from "./Button";

class UCWidgetWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.widgetApi = React.createRef();
  }

  render() {
    return (
      <>
        <Button
          classList="button button--translucent"
          action={() => this.widgetApi.current.openDialog()}
          icon="image"
          text="Choose an image"
        />
        <Widget
          ref={this.widgetApi}
          publicKey="2cfed3f2db2ca57d4237"
          imagesOnly="true"
          previewStep="true"
          crop=""
          onChange={info => this.props.setPhoto(info)}
          tabs="file camera url gdrive gphotos instagram dropbox onedrive"
        />
      </>
    );
  }
}

export default UCWidgetWrapper;
