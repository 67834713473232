import React, { Component } from "react";
// External packages
import * as Vibrant from "node-vibrant";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import { saveAs } from "file-saver";
// Custom functions
import checkForTouch from "./utils/checkForTouch";
// SVGs
import SVG from "react-inlinesvg";
import logo from "./images/logo.svg";
import heart from "./images/icons/heart--anim.svg";
import deadCarrot from "./images/food/dead-carrot.svg";
// Components
import RecipeThumb from "./components/RecipeThumb";
import RecipeModal from "./components/RecipeModal";
import Modal from "react-modal";
import SearchBar from "./components/SearchBar";
import DisplayModeToggle from "./components/DisplayModeToggle";
import ThemeToggle from "./components/ThemeToggle";
import NewRecipeButton from "./components/NewRecipeButton";
import UAParser from "ua-parser-js";
import Button from "./components/Button";
// Stylesheets
import "./stylesheets/main.scss";
// Requires
var uniqid = require("uniqid");
var deepClone = require("lodash/cloneDeep");
// Misc
const modalCloseTimeout = 400;

Modal.setAppElement("#root");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      browser: "",
      config: {
        appearance: {
          darkMode: JSON.parse(localStorage.getItem("darkMode")),
          displayMode: localStorage.getItem("displayMode") || "card"
        }
      },
      confirmCallback: null,
      currentTarget: null,
      dataLoaded: false,
      filters: {
        favorite: false
      },
      modal: {
        content: {
          large_text: "",
          small_text: [],
          confirmButton: {
            present: true,
            text: ""
          },
          cancelButton: {
            present: true,
            text: "Cancel"
          }
        },
        isOpen: false
      },
      recipeModal: {
        content: "",
        hasChanges: false,
        open: false,
        recipeData: {}
      },
      recipes: [],
      searchValue: "",
      targetRecipeId: null,
      uploadcare: {
        open: false
      }
    };
    this.menuRef = React.createRef();
    this.recipeModalRef = React.createRef();
    this.getRecipes();
  }

  // Custom methods =========================================

  addRecipe = recipe => {
    recipe.id = uniqid().toString();
    recipe.hidden = !(
      recipe.name
        .toLowerCase()
        .indexOf(this.state.searchValue.toLowerCase()) !== -1
    );
    let newState = deepClone(this.state);
    newState.recipes.push(recipe);
    newState.recipeModal = {
      content: "",
      recipeData: {},
      open: false
    };
    this.setState(newState, () => {
      this.updateLocalStorage("add", recipe, false);
    });
  };

  closeConfirmModal = () => {
    let newState = deepClone(this.state);
    newState.modal.isOpen = false;
    this.setState(newState);
  };

  closeRecipeModal = () => {
    let newState = deepClone(this.state);
    newState.recipeModal.open = false;
    this.setState(newState);
  };

  deleteRecipe = callback => {
    const id = this.state.targetRecipeId;
    const updatedRecipes = this.state.recipes.filter(recipe => {
      return recipe.id !== id;
    });
    let newState = deepClone(this.state);

    newState.recipes = updatedRecipes;
    newState.modal.isOpen = false;
    newState.recipeModal = {
      content: "",
      recipeData: {},
      open: false,
      hasChanges: false
    };
    newState.targetRecipeId = null;
    newState.confirmCallback = null;

    this.setState(newState, () => {
      this.updateLocalStorage("delete", false, id);
      setTimeout(() => {
        this.resetConfirmModal();
      }, modalCloseTimeout);
    });
  };

  downloadRecipe = id => {
    const recipe = this.state.recipes.filter(recipe => {
      return recipe.id === id;
    })[0];
    const filename =
      recipe.name.replace(/[^a-z0-9]/gi, "_").toLowerCase() + ".txt";
    let fileContent = "";

    fileContent += `Name: ${recipe.name}\n`;
    fileContent += `Prep Time: ${recipe.prepTime}\n`;
    fileContent += `Servings: ${recipe.servings}\n\n`;
    fileContent += `Ingredients:\n\n`;
    recipe.ingredients.forEach((ingredient, index) => {
      let newLine = index === recipe.ingredients.length - 1 ? "\n\n" : "\n";
      fileContent += `• ${ingredient.amount} ${ingredient.name}${newLine}`;
    });
    fileContent += `Directions:\n\n`;
    recipe.directions.forEach((direction, index) => {
      fileContent += `${index + 1}. ${direction.text}\n`;
    });

    let blob = new Blob([fileContent], {
      type: "text/plain;charset=utf-8"
    });

    saveAs(blob, filename);
  };

  editRecipe = id => {
    let newState = deepClone(this.state);
    const recipeData = newState.recipes.filter(recipe => {
      return recipe.id === id;
    })[0];
    newState.recipeModal = {
      content: "edit",
      recipeData: recipeData,
      open: true,
      hasChanges: false
    };
    this.setState(newState);
  };

  filterByFavorite = () => {
    let newState = deepClone(this.state);
    newState.filters.favorite = !newState.filters.favorite;
    this.setState(newState);
  };

  filterRecipes = val => {
    let newState = deepClone(this.state);
    val = typeof val !== "string" ? newState.searchValue : val;
    newState.searchValue = val;
    this.setState(newState);
  };

  getUserAgent = () => {
    let ua = new UAParser();
    this.setState({ browser: ua.getBrowser().name.toLowerCase() });
  };

  async getProminentColor(image) {
    let themeColor = await new Vibrant(image);
    return await themeColor.getPalette().then(palette => {
      let { rgb } = palette.Vibrant;
      let rgbStr = "rgb(";
      rgb.forEach((value, index) => {
        rgbStr += index < 2 ? `${value},` : `${value})`;
      });
      return rgbStr;
    });
  }

  async getRecipes() {
    let recipes = await JSON.parse(localStorage.getItem("recipes"));
    if (recipes !== null) {
      this.setState({ recipes }, () => {
        this.setState({ dataLoaded: true });
      });
    } else {
      this.setState({ dataLoaded: true });
    }
  }

  getRenderedContent() {
    let recipesContent = <div></div>;
    let recipesDataAttr;
    let modalSmallText = this.state.modal.content.small_text;

    if (this.state.recipes.length > 0) {
      let filteredRecipes =
        this.state.recipes.length > 0
          ? [...this.state.recipes].sort((recipeA, recipeB) =>
              recipeA.name.toLowerCase() > recipeB.name.toLowerCase() ? 1 : -1
            )
          : [];

      if (filteredRecipes.length > 0) {
        filteredRecipes.forEach(recipe => {
          recipe.hidden = false;
          if (
            recipe.name
              .toLowerCase()
              .indexOf(this.state.searchValue.toLowerCase()) === -1
          ) {
            recipe.hidden = true;
          } else if (!recipe.favorite && this.state.filters.favorite) {
            recipe.hidden = true;
          }
        });
      }
      recipesDataAttr = "true";
      recipesContent = (
        <ul
          id="recipe-thumbs"
          data-display-mode={this.state.config.appearance.displayMode}
        >
          {filteredRecipes.map((recipe, index) => (
            <RecipeThumb
              displayMode={this.state.config.appearance.displayMode}
              recipe={recipe}
              key={recipe.id}
              handleClick={this.handleClick}
              ref={recipeThumb => (this.recipeThumb = recipeThumb)}
              recipeModalOpen={this.state.recipeModal.open}
            />
          ))}
        </ul>
      );
    } else {
      recipesDataAttr = "false";
      recipesContent = (
        <div className="no-recipes center">
          <SVG src={deadCarrot} />
          <h2>You don't have any recipes!</h2>
          <p>Click the (+) button to add some.</p>
        </div>
      );
    }

    // Set modal supporting text
    if (modalSmallText.length > 0) {
      modalSmallText = modalSmallText.map((paragraph, index) => (
        <p className="body-text" key={index + 1}>
          {paragraph}
        </p>
      ));
    } else {
      modalSmallText = null;
    }

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal.isOpen}
          onAfterOpen={this.afterOpenConfirmModal}
          onRequestClose={this.closeConfirmModal}
          contentLabel="Example Modal"
          className="Modal"
          overlayClassName="Modal-overlay"
          closeTimeoutMS={modalCloseTimeout}
        >
          <section className="Modal-content">
            <h2 className="Modal-prompt">
              {this.state.modal.content.large_text}
            </h2>
            <div className="Modal-supporting-text">{modalSmallText}</div>
          </section>
          <section className="Modal-actions">
            {this.state.modal.content.cancelButton.present ? (
              <Button
                classList={
                  "button--translucent button--themed button--expand-bg"
                }
                themeColor="gray"
                action={this.closeConfirmModal}
                text={this.state.modal.content.cancelButton.text}
              />
            ) : null}
            <Button
              classList={"button--translucent button--themed button--expand-bg"}
              themeColor={
                this.state.modal.content.confirmButton.color !== ""
                  ? this.state.modal.content.confirmButton.color
                  : "red"
              }
              action={this.handleConfirm}
              text={this.state.modal.content.confirmButton.text}
            />
          </section>
        </Modal>
        <RecipeModal
          data={this.state.recipeModal}
          addRecipe={this.addRecipe}
          requestDelete={this.requestDelete}
          editRecipe={this.editRecipe}
          updateRecipe={this.updateRecipe}
          updateFavorite={this.updateFavorite}
          downloadRecipe={this.downloadRecipe}
          recipeModalRef={recipeModal => (this.recipeModalRef = recipeModal)}
          closeRecipeModal={this.closeRecipeModal}
          recipes={this.state.recipes}
          handleFormChange={this.handleFormChange}
          requestRecipeModalClose={this.requestRecipeModalClose}
          updateLocal={this.updateLocalStorage}
        />
        <main>
          <div className="action-ctr">
            <NewRecipeButton onclick={this.newRecipe} />
          </div>
          <header>
            <div className="content center max-width">
              <SVG src={logo} />
              <div className="search-actions-ctr">
                <SearchBar filterRecipes={this.filterRecipes} />
                <div className="actions">
                  <Tippy
                    animation="shift-away"
                    arrow={false}
                    boundary="viewport"
                    className="label-tooltip"
                    content="Toggle favorites"
                    delay={[400, 0]}
                    duration={200}
                  >
                    <button
                      id="favorite-filter-btn"
                      onClick={this.filterByFavorite}
                      data-favorited={this.state.filters.favorite}
                    >
                      <SVG src={heart} className="favorite-filter-icon" />
                    </button>
                  </Tippy>
                  <ThemeToggle
                    toggleTheme={this.toggleTheme}
                    darkMode={this.state.config.appearance.darkMode}
                  />
                  <DisplayModeToggle
                    toggleDisplayMode={this.toggleDisplayMode}
                    displayMode={this.state.config.appearance.displayMode}
                  />
                </div>
              </div>
            </div>
          </header>
          <section id="recipes">
            <div
              className="content center max-width"
              data-recipes={recipesDataAttr}
            >
              {recipesContent}
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }

  handleClick = (id, e) => {
    const target = e.target;
    const uploadcare = target.closest(".uploadcare--dialog__container");

    // Clicked off RecipeModal
    if (uploadcare === null) {
      if (
        !this.recipeModalRef.contains(target) &&
        this.state.recipeModal.open &&
        target.closest(".RecipeModal-root") &&
        this.state.currentTarget === e.target
      ) {
        this.requestRecipeModalClose();
      }
    }

    // Clicked on recipe thumb
    if (id !== undefined && id !== false && id !== null) {
      let newState = deepClone(this.state);
      newState.recipeModal.recipeData = this.state.recipes.filter(recipe => {
        return recipe.id === id;
      })[0];
      newState.recipeModal.open = true;
      newState.recipeModal.content = "show";
      this.setState(newState);
    }
  };

  handleConfirm = () => {
    if (typeof this.state.confirmCallback === "function") {
      this.state.confirmCallback();
    } else {
      this.closeConfirmModal();
    }
  };

  handleFormChange = () => {
    let newState = deepClone(this.state);
    newState.recipeModal.hasChanges = true;
    this.setState(newState);
  };

  handleMouseDown = e => {
    this.setState({
      currentTarget: e.target
    });
  };

  newRecipe = () => {
    let newState = deepClone(this.state);
    newState.recipeModal = {
      content: "new",
      recipeData: {},
      open: true,
      hasChanges: false
    };
    this.setState(newState);
  };

  openConfirmModal = (content, callback) => {
    if (typeof content === "object") {
      let newState = deepClone(this.state);
      newState.modal = {
        isOpen: true,
        content: content
      };
      this.setState(newState);
    } else {
      return console.error("Content must be an object");
    }
    if (typeof callback === "function") {
      callback();
    } else {
      console.error("Callback must be a function.");
    }
  };

  requestDelete = id => {
    let newState = deepClone(this.state);
    newState.modal = {
      isOpen: true,
      content: {
        large_text: "Are you sure you want to delete this recipe?",
        small_text: ["This cannot be undone."],
        confirmButton: {
          color: "red",
          present: true,
          text: "Delete"
        },
        cancelButton: {
          present: true,
          text: "Cancel"
        }
      }
    };
    newState.targetRecipeId = id;
    newState.confirmCallback = this.deleteRecipe;
    this.setState(newState);
  };

  requestRecipeModalClose = () => {
    if (
      this.state.recipeModal.hasChanges &&
      this.state.recipeModal.content === "edit"
    ) {
      let newState = deepClone(this.state);

      newState.confirmCallback = () => {
        let callbackState = deepClone(this.state);
        callbackState.modal.isOpen = false;
        callbackState.recipeModal.open = false;
        callbackState.recipeModal.hasChanges = false;
        callbackState.recipeModal.recipeData = {};
        this.setState(callbackState, () => {
          setTimeout(() => {
            this.resetConfirmModal();
          }, modalCloseTimeout);
        });
      };

      newState.modal = {
        isOpen: true,
        content: {
          large_text: "Are you sure you want to exit?",
          small_text: ["All changes will be lost."],
          confirmButton: {
            color: "red",
            present: true,
            text: "Exit"
          },
          cancelButton: {
            present: true,
            text: "Cancel"
          }
        }
      };
      this.setState(newState);
    } else {
      this.closeRecipeModal();
    }
  };

  resetConfirmModal = () => {
    let newState = deepClone(this.state);
    newState.modal.content = {
      large_text: "",
      small_text: [],
      confirmButton: {
        color: "red",
        present: true,
        text: ""
      },
      cancelButton: {
        present: true,
        text: "Cancel"
      }
    };
    this.setState(newState);
  };

  resetRecipeModal = () => {
    let newState = deepClone(this.state);
    newState.recipeModal = {
      content: "",
      recipeData: {},
      open: false,
      hasChanges: false
    };
    this.setState(newState);
  };

  toggleDisplayMode = () => {
    let newState = deepClone(this.state);
    let newMode =
      newState.config.appearance.displayMode === "card" ? "compact" : "card";

    newState.config.appearance.displayMode = newMode;

    this.setState(newState, () => {
      localStorage.setItem("displayMode", newMode);
    });
  };

  toggleTheme = () => {
    let newState = deepClone(this.state);
    newState.config.appearance.darkMode = !this.state.config.appearance
      .darkMode;
    this.setState(newState, () => {
      let themeMode = this.state.config.appearance.darkMode ? "dark" : "light";
      document.body.setAttribute("data-theme-mode", themeMode);
      localStorage.setItem("darkMode", this.state.config.appearance.darkMode);
    });
  };

  updateFavorite = id => {
    let newState = deepClone(this.state);
    let newRecipes = newState.recipes;
    for (var i in newRecipes) {
      if (newRecipes[i].id === id) {
        newRecipes[i].favorite = !newRecipes[i].favorite;
      }
    }
    let updatedRecipe = newRecipes.filter(recipe => {
      return recipe.id === id;
    })[0];
    newState.recipeModal.recipeData = updatedRecipe;
    newState.recipes = newRecipes;
    this.setState(newState);
  };

  updateLocalStorage = (action, recipe = false, id = false) => {
    let oldRecipes = JSON.parse(localStorage.getItem("recipes"));
    let updatedRecipes = JSON.parse(JSON.stringify(oldRecipes));

    switch (action) {
      case "add":
        updatedRecipes.push(recipe);
        break;
      case "delete":
        updatedRecipes = oldRecipes.filter(recipeItem => {
          return recipeItem.id !== id;
        });
        break;
      case "update":
        updatedRecipes = [...this.state.recipes];
        break;
      default:
        break;
    }

    localStorage.setItem("recipes", JSON.stringify(updatedRecipes));
  };

  updateRecipe = recipe => {
    let newState = deepClone(this.state);

    recipe.hidden = !(
      recipe.name
        .toLowerCase()
        .indexOf(this.state.searchValue.toLowerCase()) !== -1
    );

    newState.recipes.forEach(function(item, index) {
      if (item.id === recipe.id) {
        newState.recipes[index] = recipe;
      }
    }, newState.recipes);

    newState.recipeModal = {
      content: "show",
      recipeData: recipe,
      open: true
    };

    this.setState(newState, () => {
      this.updateLocalStorage("update", recipe, recipe.id);
    });
  };

  // Lifecycle methods ======================================

  componentDidMount() {
    document.addEventListener("click", this.handleClick.bind(this, false));
    if (localStorage.getItem("recipes") === null) {
      localStorage.setItem("recipes", JSON.stringify([]));
    }
    if (localStorage.getItem("visited") === null) {
      const callback = () => {
        localStorage.setItem("visited", JSON.stringify(true));
      };

      setTimeout(() => {
        this.openConfirmModal(
          {
            large_text: "Merry Christmas! 🎅🏼",
            small_text: [
              "This is an app I've been working on for the last few months that lets you view and manage all your recipes right from your phone or computer, although they aren't synced currently.",
              "iOS users: For the best experience, add a bookmark of this site to your homescreen.",
              "Please let me know if you discover any bugs :)"
            ],
            confirmButton: {
              color: "rgb(109,104,237)",
              present: true,
              text: "Close"
            },
            cancelButton: {
              present: false,
              text: "Cancel"
            }
          },
          callback
        );
      }, 500);
    }
    this.getUserAgent();
    checkForTouch();
  }

  render() {
    let themeMode = this.state.config.appearance.darkMode ? "dark" : "light";
    document.body.setAttribute("data-theme-mode", themeMode);
    return (
      <div
        id="app-main"
        data-user-agent={this.state.browser.toLowerCase()}
        onMouseDown={this.handleMouseDown}
      >
        {this.state.dataLoaded ? this.getRenderedContent() : null}
      </div>
    );
  }
}

export default App;
