import React, { Component } from "react";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";
import heart from "../images/icons/heart--anim.svg";

class FavoriteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favorite: this.props.favorite
    };
  }

  // Lifecycle methods ======================================

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      favorite: nextProps.favorite
    };
  }

  // Custom methods =========================================

  handleClick = async () => {
    await this.props.updateFavorite(this.props.id);
    this.props.updateLocal("update");
  };

  getRenderedContent() {
    return (
      <button
        className="favorite-btn shadow shadow--dark shadow--medium"
        data-favorited={this.state.favorite}
        onClick={this.handleClick}
      >
        <SVG src={heart} />
      </button>
    );
  }

  render() {
    return this.getRenderedContent();
  }
}

FavoriteButton.propTypes = {
  favorite: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
};

export default FavoriteButton;
