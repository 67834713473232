import React, { Component } from "react";
import PropTypes from "prop-types";
import hamburger from "../images/food/hamburger-bg.svg";
import avocados from "../images/food/avocados-bg.svg";
import grapes from "../images/food/grapes-bg.svg";
import broccoli from "../images/food/broccoli-bg.svg";
import watermelon from "../images/food/watermelon-bg.svg";
import carrots from "../images/food/carrots-bg.svg";
import bananas from "../images/food/bananas-bg.svg";
import sandwich from "../images/food/sandwich-bg.svg";
var equal = require("fast-deep-equal");

class RecipeThumb extends Component {
  // Custom methods =========================================

  setFallback = e => {
    e.target.src = this.getPlaceholder();
  };

  getPlaceholder = () => {
    const bgList = [
      hamburger,
      avocados,
      grapes,
      broccoli,
      watermelon,
      carrots,
      bananas,
      sandwich
    ];
    let randIndex = Math.floor(Math.random() * bgList.length);
    return bgList[randIndex];
  };

  getRenderedContent() {
    let { name, photo, id, hidden } = this.props.recipe;

    return (
      <li
        className="recipe-thumb"
        data-recipe-id={id}
        onClick={this.props.handleClick.bind(this, id)}
        data-hidden={hidden}
      >
        <div className="overflow-ctr">
          <img
            src={photo}
            alt={name}
            className="recipe-thumb-image"
            onError={this.setFallback}
          />
        </div>
        <div className="recipe-thumb-name" style={{}}>
          {name}
        </div>
      </li>
    );
  }

  // Lifecycle methods ======================================

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.displayMode !== nextProps.displayMode) {
      return true;
    } else if (equal(nextProps.recipe, this.props.recipe)) {
      return false;
    }
    return true;
  }

  render() {
    return this.getRenderedContent();
  }
}

RecipeThumb.propTypes = {
  recipe: PropTypes.object.isRequired
};

export default RecipeThumb;
