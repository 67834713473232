import React, { Component } from "react";
import PropTypes from "prop-types";
import ChecklistItem from "./ChecklistItem";
import Button from "./Button";
var equal = require("fast-deep-equal");

class Checklist extends Component {
  constructor(props) {
    super(props);
    this.state = { items: this.props.listData };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!equal(prevProps, this.props)) {
      this.setState({ items: this.props.listData });
    }
  }

  // Custom methods =========================================

  uncheckAll = () => {
    let newChecklist = [...this.state.items];
    newChecklist.forEach(item => {
      item.checked = false;
    });
    this.setState({ items: newChecklist }, this.props.updateLocal());
  };

  updateList = id => {
    let newChecklist = [...this.state.items];
    newChecklist.forEach(item => {
      if (item.id === id) {
        item.checked = !item.checked;
      }
    });
    this.setState({ items: newChecklist }, this.props.updateLocal());
  };

  getRenderedContent() {
    const listType = this.props.listType;

    if (listType === "table") {
      return (
        <div className="checklist" data-list-type={listType}>
          <div className="checklist-overflow-container">
            <table>
              <tbody>
                {this.state.items.map((item, index) => (
                  <ChecklistItem
                    themeColor={this.props.themeColor}
                    itemData={item}
                    key={index + 1}
                    updateList={this.updateList}
                    id={item.id}
                    listType={listType}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <Button
            action={this.uncheckAll}
            themeColor={this.props.themeColor}
            icon={"reset"}
            text={"Reset"}
            classList={"small button--translucent button--themed"}
          />
        </div>
      );
    } else if (listType === "list") {
      return (
        <div className="checklist" data-list-type={listType}>
          <ul>
            {this.state.items.map((item, index) => (
              <ChecklistItem
                themeColor={this.props.themeColor}
                itemData={item}
                key={index + 1}
                updateList={this.updateList}
                id={item.id}
                listType={listType}
              />
            ))}
          </ul>
          <Button
            action={this.uncheckAll}
            themeColor={this.props.themeColor}
            icon={"reset"}
            text={"Reset"}
            classList={"small button--translucent button--themed"}
          />
        </div>
      );
    }
  }

  render() {
    return this.getRenderedContent();
  }
}

Checklist.propTypes = {
  listData: PropTypes.array.isRequired,
  listType: PropTypes.string.isRequired
};

export default Checklist;
